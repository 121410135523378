<template>
    <div class="page address-detail">
        <div class="line border">
            <div class="name">联系人</div>
            <div class="value">
                <input class="input" type="text" placeholder="请填写姓名" v-model="form.name" />
            </div>
        </div>
        <div class="line gender">
            <div class="value">
                <div class="item" @click="switchGender(1)" :class="{ select: form.gender == 1 }">
                    女士
                </div>
                <div class="item" @click="switchGender(0)" :class="{ select: form.gender == 0 }">
                    先生
                </div>
            </div>
        </div>
        <div class="line border">
            <div class="name">电话</div>
            <div class="value">
                <input
                    class="input"
                    type="text"
                    placeholder="请填写收货手机号码"
                    v-model="form.mobile"
                />
            </div>
        </div>
        <div class="line border" :class="{ 'show-remote-tip': isShowRemoteTip }">
            <div class="name">地址</div>
            <div class="value icon-right" @click="toSearch">
                <div class="text address" v-if="form.mainAddress">{{ form.mainAddress }}</div>
                <div class="text address tip" v-else>点击选择收货地址</div>
            </div>
        </div>
        <div class="line border">
            <div class="name">门牌号</div>
            <div class="value">
                <input
                    class="input"
                    type="text"
                    placeholder="如：8号楼1层102室"
                    v-model="form.detailAddress"
                />
            </div>
        </div>
        <div class="btn" @click="submit">确认</div>
        <div v-if="id" class="delete btn" @click="deleteItem">删除</div>
    </div>
</template>
<script>
const _form = {
    defaultAddress: false,
    detailAddress: '',
    gender: 0,
    lat: '',
    lon: '',
    mainAddress: '',
    roadAddress: '',
    mobile: '',
    name: '',
    poiType: '',
    regionCode: '',
    isSubmitIng: false,
};
import { PAGE_ADDRESS_SEARCH, PAGE_ADDRESS_LIST } from 'page';
import { GET_ADDRESS_ITEM, EDIT_ADDRESS_ITEM, ADD_ADDRESS_ITEM, DELETE_ADDRESS_ITEM } from 'api';
export default {
    data() {
        return {
            pageType: 0,
            id: 0,
            form: Object.assign({}, _form),
        };
    },
    computed: {
        isExceedMaxLength() {
            var bytesCount = 0;
            for (var i = 0; i < this.form.detailAddress.length; i++) {
                var c = this.form.detailAddress.charAt(i);
                if (/^[\u0000-\u00ff]$/.test(c)) {
                    //匹配双字节
                    bytesCount += 1;
                } else {
                    bytesCount += 2;
                }
            }
            if (bytesCount <= 30) {
                return false;
            } else {
                return true;
            }
        },
        isShowRemoteTip() {
            let form = this.form;
            let currentLocation = this.$store.getters.getCurrentLocation;

            if (form.lon && form.lat && currentLocation.lon && currentLocation.lat) {
                if (
                    this.calculateDistance(
                        form.lat,
                        form.lon,
                        currentLocation.lat,
                        currentLocation.lon
                    ) > 1
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.id = vm.$route.query.id || 0;
            vm.isSubmitIng = false;
            if (from.path == PAGE_ADDRESS_SEARCH && from.query.addressInfo) {
                const addressInfo = from.query.addressInfo;
                console.log(addressInfo);
                vm.form.mainAddress = `${addressInfo.title}`;
                vm.form.roadAddress = `${addressInfo.address}`;
                vm.form.lat = addressInfo.lat;
                vm.form.lon = addressInfo.lon;
                vm.form.poiType = addressInfo.poiType;
                vm.form.regionCode = addressInfo.adCode + '000000';
            } else {
                vm.pageType = vm.$route.query.type;
                if (vm.pageType == 1) {
                    vm.getData();
                }
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        if (to.path == PAGE_ADDRESS_LIST) {
            this.form = { ..._form };
        }
        next();
    },
    created() {
        console.log(this.$store.getters.getCurrentLocation);
    },
    methods: {
        getData() {
            this.$util.get(`${GET_ADDRESS_ITEM}/${this.id}`).then(res => {
                if (res.success) {
                    for (let k in _form) {
                        this.form[k] = res.data[k];
                    }
                }
            });
        },
        switchGender(index) {
            this.form.gender = index;
        },
        toSearch() {
            this.$util.pageJump(PAGE_ADDRESS_SEARCH);
        },
        submit() {
            if (this.isSubmitIng) {
                return;
            }
            this.isSubmitIng = true;
            let msg = '';
            if (!this.form.name) {
                msg = '请输入联系人';
            } else if (this.form.name.length > 10) {
                msg = '请输入10个字的姓名';
            } else if (!this.form.mobile) {
                msg = '请输入电话';
            } else if (!this.form.mainAddress) {
                msg = '请选择收货地址';
            } else if (!this.form.detailAddress) {
                msg = '请输入详细地址';
            } else if (this.isExceedMaxLength) {
                msg = '详细地址超过30个字符(15个汉字)';
            }
            if (msg) {
                this.$toast(msg);
                this.isSubmitIng = false;
                return;
            }
            let requestUrl = '';
            let requestData = this.form;
            if (this.id) {
                requestData.id = this.id;
                requestUrl = EDIT_ADDRESS_ITEM;
            } else {
                requestUrl = ADD_ADDRESS_ITEM;
            }
            this.$util.post(requestUrl, requestData).then(res => {
                if (res.success) {
                    this.$toast(`${this.id ? '修改' : '添加'}成功`);
                    setTimeout(() => {
                        this.$util.pageBack();
                    }, 500);
                } else {
                    this.isSubmitIng = false;
                }
            });
        },
        deleteItem() {
            this.$confirm.open({
                title: '温馨提示',
                message: '您是否删除该地址？',
                confirmText: '删除',
                success: () => {
                    this.$util.get(`${DELETE_ADDRESS_ITEM}/${this.id}`).then(res => {
                        if (res.success) {
                            this.$toast('删除成功');
                            setTimeout(() => {
                                this.$util.pageBack();
                            }, 500);
                        } else {
                            this.$toast(res.message);
                        }
                    });
                },
            });
        },
        calculateDistance(lat1, lon1, lat2, lon2) {
            const earthRadius = 6371; // 地球半径（单位：公里）
            const dLat = ((lat2 - lat1) * Math.PI) / 180;
            const dLon = ((lon2 - lon1) * Math.PI) / 180;
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((lat1 * Math.PI) / 180) *
                    Math.cos((lat2 * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = earthRadius * c;
            return distance;
        },
    },
};
</script>
<style lang="less" scoped>
@import url('~@/lib/base.less');
.page {
    height: 100vh;
    background: white;
    box-sizing: border-box;
    padding-top: .pxToRem(30) [];
}
.line {
    display: flex;
    height: .pxToRem(90) [];
    line-height: .pxToRem(90) [];
    background: white;
    font-size: .pxToRem(28) [];
    color: black;
    border: .pxToRem(1) [] solid white;
    width: .pxToRem(710) [];
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: .pxToRem(45) [];
    margin-bottom: .pxToRem(10) [];
    overflow: hidden;
    .name {
        margin-left: .pxToRem(28) [];
        font-weight: 600;
        white-space: nowrap;
    }
    .value {
        flex: 1;
        margin-left: .pxToRem(30) [];
        margin-right: .pxToRem(30) [];
        box-sizing: border-box;
        .input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: .pxToRem(86) [];
            line-height: .pxToRem(86) [];
            font-size: .pxToRem(28) [];
            text-align: right;
            border-radius: .pxToRem(10) [];
        }
        .text {
            height: .pxToRem(90) [];
            line-height: .pxToRem(90) [];
            text-align: right;
            // padding-right: .pxToRem(30) [];
            font-size: .pxToRem(28) [];
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap;
            &.address {
                padding-right: .pxToRem(45) [];
                float: right;
                max-width: .pxToRem(500) [];
            }
            &.tip {
                color: rgba(0, 0, 0, 0.6);
            }
        }
        .item {
            width: .pxToRem(190) [];
            height: .pxToRem(60) [];
            line-height: .pxToRem(60) [];
            text-align: center;
            display: inline-block;
            float: right;
            margin-top: .pxToRem(14) [];
            box-sizing: border-box;
            font-size: .pxToRem(28) [];
            color: black;
            border: .pxToRem(1) [] solid #e9e9e9;
            border-radius: .pxToRem(8) [];
            margin-left: .pxToRem(20) [];
            &.select {
                color: @theme_color_first;
                border: .pxToRem(1) [] solid @theme_color_first;
                background: #fdf0f0;
            }
        }
        &.icon-right {
            &:after {
                content: ' ';
                .base-center-y;
                right: 0;
                .sq(32);
                background: url('~@/assets/icons/icon-right.png');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    &:last-child {
        border-bottom: 0;
    }
    &.gender {
        height: .pxToRem(100) [];
    }
    &.border {
        border: .pxToRem(1) [] solid #e9e9e9;
    }
    &.show-remote-tip {
        // height: .pxToRem(110) [];
        .text {
            height: .pxToRem(66) [];
            line-height: .pxToRem(66) [];
        }
        &::before {
            content: '当前地址离你较远，请确认';
            position: absolute;
            bottom: .pxToRem(12) [];
            right: .pxToRem(100) [];
            line-height: .pxToRem(20) [];
            font-size: .pxToRem(22) [];
            color: @theme_color_first;
        }
    }
}
.btn {
    margin: 0 auto;
    margin-top: .pxToRem(40) [];
    background: @theme_color_first;
    width: .pxToRem(710) [];
    height: .pxToRem(80) [];
    line-height: .pxToRem(80) [];
    text-align: center;
    color: white;
    font-size: .pxToRem(28) [];
    border-radius: .pxToRem(8) [];
    &.delete {
        color: rgba(0, 0, 0, 0.9);
        background: unset;
        border: .pxToRem(1) [] solid @theme_color_first;
        margin-top: .pxToRem(20) [];
    }
}
</style>
